import * as React from 'react'

const BODY_TEMPLATE = 
`If you're requesting to join, let us know your Name, Preferred Pronoun, anything about yourself and reason for interest below.

Name:

Preferred Pronouns:

About youself:

Reason for interest:


For other enquiries, you may remove this template and reach out directly.

Hope to hear from you 😄!`;

const EMAIL = 'youyouwuv@gmail.com'

const Footer = () => {
    const [emailURL, setEmailURL] = React.useState('')

    React.useEffect(() => {
        setEmailURL(_e => `mailto:${EMAIL}?body=${encodeURI(BODY_TEMPLATE)}`)
    }
    , [])
    return (
        <div className="bg-white">
        <div className="max-w-screen-xl px-4 py-12 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8">
            <p className="mt-8 text-base leading-6 text-center text-gray-600">
                    YouYou Singapore
            </p>
            <div className="flex justify-center mt-8 space-x-6">
                <a href="https://www.facebook.com/youyouwuv/" className="text-gray-400 hover:text-gray-500">
                    <span className="sr-only">Facebook</span>
                    <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                        <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd"></path>
                    </svg>
                </a>
                <a href={emailURL} className="text-gray-400 hover:text-gray-500">
                    <span className="sr-only">Email</span>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                    </svg>
                </a>
            </div>
            
        </div>
        </div>
    )
}

export default Footer;