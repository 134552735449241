import * as React from 'react';
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import './navbar.css'

function Navbar() {
    return (
        <nav className="font-sans flex flex-col text-center content-center sm:flex-row sm:text-left sm:justify-between py-2 px-6 bg-white shadow sm:items-baseline w-full">
            <div className="mb-2 sm:mb-0 inner text-center">
                <Link to="/">
                    <StaticImage src='../images/YouYou_colour.webp' width={100} alt="YouYou" />
                </Link><br />
                <span className="text-xs text-gradient bg-gradient-to-r from-gray-500 to-gray-500 hover:from-red-500 hover:via-yellow-300 hover:via-blue-500 hover:to-indigo-500 hover:to-purple-400">Where it's okay to be you.</span>
            </div>

            <div className="sm:mb-0 self-center">
                <Link to="/" className="text-base md:text-lg no-underline text-gradient bg-gradient-to-r from-black to-black hover:from-pink-500 hover:to-yellow-500 ml-2 px-1 font-sylexiad">Home</Link>
                <Link to="/about-us" className="text-base md:text-lg no-underline text-gradient bg-gradient-to-r from-black to-black hover:from-pink-500 hover:to-yellow-500 ml-2 px-1 font-sylexiad">About Us</Link>
                <Link to="/meetings" className="text-base md:text-lg no-underline text-gradient bg-gradient-to-r from-black to-black hover:from-pink-500 hover:to-yellow-500 ml-2 px-1 font-sylexiad">Gatherings</Link>
                <div className="relative inline-block text-left group">
                    <Link href="#" className="text-lg no-underline text-gradient bg-gradient-to-r from-black to-black hover:from-pink-500 hover:to-yellow-500 ml-2 px-1 font-sylexiad" id="menu-button" aria-expanded="true" aria-haspopup="true">Resources</Link>
                    <ul
                        className="bg-white border rounded-sm transform scale-0 group-hover:scale-100 absolute 
                    transition duration-150 ease-in-out origin-top min-w-32 z-10 list-outside"
                    >
                        <li className="rounded-sm px-3 py-1 hover:bg-gray-100"><Link to="/mental-health" className="text-base md:text-lg no-underline text-gradient bg-gradient-to-r from-black to-black hover:from-pink-500 hover:to-yellow-500 font-sylexiad">Mental Health Resources</Link></li>
                        <li className="rounded-sm px-3 py-1 hover:bg-gray-100"><Link to="/queer-resources" className="text-base md:text-lg no-underline text-gradient bg-gradient-to-r from-black to-black hover:from-pink-500 hover:to-yellow-500 font-sylexiad">Queer Resources</Link></li>
                        <li className="rounded-sm px-3 py-1 hover:bg-gray-100"><Link to="/islam-and-queerness" className="text-base md:text-lg no-underline text-gradient bg-gradient-to-r from-black to-black hover:from-pink-500 hover:to-yellow-500 font-sylexiad">Islam and LGBTQ+</Link></li>
                        <li className="rounded-sm px-3 py-1 hover:bg-gray-100"><Link to="/christianity-and-queerness" className="text-base md:text-lg no-underline text-gradient bg-gradient-to-r from-black to-black hover:from-pink-500 hover:to-yellow-500 font-sylexiad">Christianity and LGBTQ+</Link></li>
                    </ul>
                </div>
                <Link to="/contact" className="text-md md:text-lg no-underline text-gradient bg-gradient-to-r from-black to-black hover:from-pink-500 hover:to-yellow-500 ml-2 px-1 font-sylexiad">Contact Us</Link>
            </div>
        </nav>
    )
}

export default Navbar;