import * as React from 'react';

export const ContactContext = React.createContext({});

const BODY_TEMPLATE = 
`If you're requesting to join, let us know your Name, Preferred Pronoun, anything about yourself and reason for interest below.

Name:

Preferred Pronouns:

About youself:

Reason for interest:


For other enquiries, you may remove this template and reach out directly.

Hope to hear from you 😄!`;

const EMAIL = 'youyouwuv@gmail.com'

export const ContactProvider = ({children}) => {
    const [emailURL, setEmailURL] = React.useState('')
    React.useEffect(() => {
        setEmailURL(_e => `mailto:${EMAIL}?body=${encodeURI(BODY_TEMPLATE)}`)
    }
    , [])
    return (
    <ContactContext.Provider value={{emailURL: emailURL}}>
        {children}
    </ContactContext.Provider>
    )
}