import * as React from 'react'
import {Helmet} from 'react-helmet'
import Navbar from './navbar'
import Footer from './footer'
import ogImage from '../images/YouYou_colour.jpg'
import favIcon from '../images/favicon.ico'
import { ContactProvider } from '../context/contact'
import '../styles/global.css'

function Layout({children, title, keywords}) {
    return (
        <div>
            <Helmet htmlAttributes={{
                lang: 'en',
            }}
            title={title}
            link={[
                {
                    rel: "icon",
                    type: "image/jpg",
                    href: favIcon
                }
            ]}>
                <meta name="viewport" content='initial-scale=1' />
                <meta name='keywords' content={keywords ? keywords.join() : 'LGBTQ,singapore,support' }/>
                <meta name="description" content={"YouYou is an interfaith, pluralistic peer support group for LGBTQIA and religious minorities to equally and honestly acknowledge the benefits and harms of institutions and communities we experienced."} />
                <meta property="og:site_name" content="YouYou" />
                <meta property="og:title" content="YouYou" />
                <meta property="og:url" content="https://youyou.family" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="YouYou is an interfaith, pluralistic peer support group for LGBTQIA and religious minorities to equally and honestly acknowledge the benefits and harms of institutions and communities we experienced."></meta>
                <meta property="og:image" content={ogImage} />
                
            </Helmet>
            <Navbar />
            <ContactProvider>
                {children}
            </ContactProvider>
            <Footer />
        </div>
    )
}

export default Layout;